// Product info
// ============
//

// Main component
// --------------

.product-info {
	h3 {
		font-size: 1.5rem;
	}
}
