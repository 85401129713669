// Faq ask
// =======

// Main component
// --------------

.faq-ask {
	display: flex;
}

// Image
// -----

.faq-ask__image {
	position: relative;
	width: 5rem;
	height: 5rem;

	flex: 0 0 5rem;
	margin-right: 1.5rem;

	@include media-breakpoint-up('lg') {
		width: 10rem;
		height: 11.25rem;

		flex: 0 0 10rem;
	}

	img {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;

		border-radius: 1.25rem;
		object-fit: cover;
		object-position: 50% 50%;
	}
}

// Body
// ----

.faq-ask__body {
	h3 {
		margin-bottom: .5rem;

		font-size: 1.125rem;

		@include media-breakpoint-up('lg') {
			margin-bottom: 1.5rem;
		}
	}
}
