// Collapse panel
// ==============

// Main component
// --------------

.cpanel {
	position: relative;

	padding: 1.5rem 4rem 1.5rem 2rem;
	border: 3px solid $clr--panel-border;
	margin-bottom: 1rem;

	border-radius: 1.25rem;
	cursor: pointer;
	transition: background .2s ease-in;

	&:after {
		position: absolute;
		top: 2rem;
		right: 1.5rem;
		display: block;
		width: 1.125rem;
		height: .625rem;

		background: url(/media/img/chevron.svg) no-repeat;
		content: '';
		transition: transform .2s ease-in;
	}

	h3 {
		margin-bottom: 0;

		font-size: 1rem;
		transition: margin .1s ease .2s;
	}

	p {
		overflow: hidden;
		max-height: 0;
		margin-bottom: 0;

		opacity: 0;
		transition: all .2s ease-in;
	}

	&--active {
		background-color: #FFFFFF;

		&:after {
			transform: rotate(180deg);
		}

		h3 {
			margin-bottom: 1rem;

			transition-delay: 0s;
			transition-duration: 0s;
		}

		p {
			max-height: 100rem;

			opacity: 1;
		}
	}
}
