// Footer
// ======

// Main component
// --------------

.footer {
	.navbar-toggler {
		display: none;
	}
}
