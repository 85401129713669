// Box
// ===

// Main component
// --------------

.box {
	padding: 0;
	list-style: none;

	@include media-breakpoint-up('lg') {
		display: flex;
		justify-content: space-between;
	}

	// Full
	&--full {
		flex-direction: column;
	}
}

// Item
// ----

.box__item {
	display: flex;

	min-height: 4.8125rem;
	align-items: center;
	padding: 1rem;
	margin: 1rem;

	background-color: #FFFFFF;
	border-radius: 1.25rem;

	@include media-breakpoint-up('lg') {
		flex: 0 1 12.875rem;
	}
    img {
        max-width: 30px;
        max-height: 30px;
    }
	&--active {
		position: relative;

		background-color: $clr--blue !important;
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
		color: #FFFFFF;
		&::before {
			position: absolute;
			width: 0;
		  height: 0;
		  border-left: 20px solid transparent;
		  border-right: 20px solid transparent;
		  border-top: 20px solid #379EFF;
			content: '';
			display: block;
			bottom: -20px;
		}
		&:after {
			position: absolute;
			top: 0;
			right: 0;

			display: inline-block;
			width: 24px;
			height: 100%;

			background: url('data:image/svg+xml;utf8,<svg version="1.1" id="Vrstva_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 49.7 99.4" preserveAspectRatio="none" xml:space="preserve"><g><polygon fill="#379EFF" points="49.7,49.7 0,0 0,99.4"/></g></svg>');
			background-size: 24px 100%;
			content: '';
			transform: translateX(100%);
		}
	}

	.box--full & {
		padding: 1rem 2.5rem;
		cursor: pointer;

		@include media-breakpoint-up('lg') {
			flex: 0 0 100%;
		}
	}

	.box--center & {
		justify-content: center;
	}

	.box--colored & {
		background-color: $clr--primary-regular;
	}
}

.prokoho-wrap {
	.box__item {
		cursor: pointer;
		&.box__item--active {
			border-radius: 1.25rem !important;
			&::after {
				display: none;
			}
		}
	}
	div.prokoho {
		> p {
			padding: 25px;
			border-radius: 1.25rem !important;
			background:#fff;
		}
	}
}
