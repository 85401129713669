// Buttons
// =======

// Main component
// --------------

.button {
	@extend .btn;

	padding: .9375rem 4.5rem;

	border-radius: 1.75rem;
	color: #FFFFFF;
	font-weight: 700;
	text-transform: uppercase;

	&--primary {
		background-color: $clr--blue;

		&:hover,
		&:active,
		&.button--active {
			background-color: darken($clr--blue, 15%);
			color: #FFFFFF;
		}
	}

	&--small {
		padding: .625rem 2.5rem;

		font-size: .8125rem;
	}

	&--block {
		width: 100%;
		padding-right: 0;
		padding-left: 0;
	}
}
