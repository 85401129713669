// Fonts
// =====

@font-face {
	font-family: GitanLatin;
	font-style: normal;
	font-weight: 700;
	src: url(/media/fonts/gitan_latin_bold-webfont.woff2) format('woff2'), url(/media/fonts/gitan_latin_bold-webfont.woff) format('woff');
}

@font-face {
	font-family: GitanLatin;
	font-style: italic;
	font-weight: 400;
	src: url(/media/fonts/gitan_latin_italic-webfont.woff2) format('woff2'), url(/media/fonts/gitan_latin_italic-webfont.woff) format('woff');
}

@font-face {
	font-family: GitanLatin;
	font-style: normal;
	font-weight: 400;
	src: url(/media/fonts/gitan_latin_regular-webfont.woff2) format('woff2'), url(/media/fonts/gitan_latin_regular-webfont.woff) format('woff');
}
