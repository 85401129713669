// Aside image
// ===========

// Main component
// --------------

.aside-image {
	position: relative;

	width: 14rem;
	height: 8rem;

	@include media-breakpoint-up('lg') {
		width: 31.25rem;
		height: 8rem;
	}

	img {
		position: absolute;

		bottom: -5rem;
		left: -4rem;

		@include media-breakpoint-up('lg') {
			bottom: -10rem;
			left: -8rem;
		}
	}
}
