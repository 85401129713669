// Headings
// ========

// Headline 1
// ----------

h1 {
	margin-top: 3rem;

	font-size: 3rem;

	&.sm-smaller {
		@include media-breakpoint-down('sm') {
			font-size: 2.2rem;
		}
	}

	.section--home & {
		font-size: 2.2rem;
	}
}

h2 {
	margin-top: 3.5rem;

	font-size: 1.75rem;
}

h3 {
	font-size: 1rem;
}
