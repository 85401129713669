// Well
// ====

.well {
	padding: 1.75rem;

	border-radius: 1.25rem;

	&--warning {
		background-color: $clr--warning;
	}
}
