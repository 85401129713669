// Nav
// ===

// Main component
// --------------

.nav {
	z-index: 2;
}

// Item
// ----

.nav__item {
	font-size: 1.375rem;
}

// Link
// ----

.nav__link {
	display: block;
	padding: 2rem;
	color: $clr--default;
	.nav__item--active & {
		color: #0056b3;
	}
}
