// Navbar
// ======
//

// Brand
// -----

.navbar__brand {
	@extend .navbar-brand;

	font-size: 1.625rem;
}
