// Con
// ===

// Main component
// --------------

.con {
	padding-left: 2.125rem;

	background: url(/media/img/thumbs-down.svg) no-repeat 0 .5rem;

	h3 {
		color: $clr--roman;
	}
}
