// Header image
// ============

// Main component
// --------------

.himage {
	display: block;
	width: 100%;
	max-width: 27.125rem;
	margin: 0 auto;
}
