// Variables
// =========

// Colors
// ------
$clr--blue: #379EFF;
$clr--default: #24261D;
$clr--primary-light: #FEFBFB;
$clr--primary-regular: #FCF1F1;
$clr--primary-dark: #FDE0E0;
$clr--roman: #DD6567;

$clr--panel-border: #F7F0F5;

$clr--warning: #FFFBE4;
