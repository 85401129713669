// Media
// =====

// Main component
// --------------

.media {
	display: block;
	padding: .4375rem;
	margin-bottom: 2rem;

	background-color: #FFFFFF;
	border-radius: 1.25rem;
	box-shadow: 0 2px 24px rgba(98, 135, 100, .09);
	color: $clr--default;

	&:hover {
		color: $clr--default;
		text-decoration: none;
	}

	&--product,
	&--simple {
		text-align: center;
	}

	&--product {
		display: flex;
		padding: .875rem .4375rem;

		align-items: center;
	}

	&--simple {
		box-shadow: none;
	}
    &.edited-images {
        div.media__image {
            padding: 0px;
            height: 250px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                position: relative;
                top: auto;
                right: auto;
                bottom: auto;
                left: auto;
                width: 100%;
                height: auto;
            }
        }
    }
}

// Image
// -----

.media__image {
	position: relative;

	overflow: hidden;
	padding: 35.4% 0;
	margin-bottom: 1rem;

	border-radius: .8125rem;

	img {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;

		object-fit: cover;
		object-position: 50% 50%;
		transition: transform .3s ease-out;
	}

	.media--product &,
	.media--simple & {
		padding: 50% 0;

		img {
			object-fit: contain;
		}
	}

	.media--extend & {
		padding: 50%;
	}

	.media--product & {
		padding: 20% 0;

		flex: 0 0 50%;
	}

	.media--simple & {
		img {
			width: 85%;
			margin: 0 auto;
		}
	}

	.media:not(.media--simple):hover & {
		img {
			transform: scale(1.2, 1.2);
		}
	}
}

// Body
// ----

.media__body {
	overflow: hidden;
	height: 9rem;
	padding: 0 1rem;

	.media--extend & {
		height: 11.75rem;
	}

	.media--product & {
		height: auto;
		width: 100%;
	}
}

// Title
// -----

.media__title {
	.media--product & {
		margin-bottom: 1.5rem;
	}
}

// Content
// -------

.media__content,
.media__body p {
	overflow: hidden;
	max-height: 5rem;

	font-size: .8125rem;
	line-height: 1.25rem;

	.media--product & {
		max-height: 3.5rem;
		margin-bottom: 1.5rem;
	}

	.media--extend & {
		max-height: 7.5rem;
	}
}

// Button
// ------

.media__button {
	margin-bottom: 2rem;
}
