// Gallery
// =======

// Main component
// --------------

.gallery {
	margin: 0 2rem 3rem;
}

// Items
// -----

.gallery__items {
	display: flex;
	justify-content: space-evenly;
}

// Item
// ----

.gallery__item {
	position: relative;

	overflow: hidden;
	flex: 0 1 28%;
	padding: 11% 0;

	border-radius: 1rem;

	img {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;

		object-fit: contain; 
		object-position: 50% 50%;
	}

	&--main {
		padding: 35% 0;
		margin-bottom: 1rem;
	}
}
