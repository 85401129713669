// Bcon main stylesheet
// ====================
//
// Bootstrap
// ---------
@import 'bootstrap/bootstrap-reboot';
@import 'bootstrap/bootstrap-grid';
@import 'bootstrap/buttons';
@import 'bootstrap/navbar';
@import 'bootstrap/transitions';
@import 'bootstrap/utilities';
@import 'bootstrap/forms';
@import 'bootstrap/alert';
// Variables
// ---------
@import 'core/variables';
// Fonts
// -----
@import 'core/fonts';
// Base
// ----
@import 'base/body';
@import 'base/headings';
@import 'base/typography';
// Utilities
// ---------
@import 'utilities/ver-ali';
// Components
// ----------
@import 'components/aside-image';
@import 'components/box';
@import 'components/breadcrumb';
@import 'components/button';
@import 'components/con';
@import 'components/cpanel';
@import 'components/faq-ask';
@import 'components/footer';
@import 'components/gallery';
@import 'components/himage';
@import 'components/media';
@import 'components/nav';
@import 'components/navbar';
@import 'components/product-info';
@import 'components/section';
@import 'components/well';

// Custom
// ------

.article__image {
  position: relative;

  overflow: hidden;
  height: 100%;
  padding: 35.4% 0;

  border-radius: .8125rem;

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: 50% 50%;
    transition: transform .3s ease-out;
  }
}

.box-img * {
  max-width: 100%;
}

.article-content {
  p {
    line-height: 2rem;
  }

  img {
    max-width: 100%;
  }
}

// Poradna panel
// -------------

.ppanel {
  position: relative;

  padding: 1.5rem 4rem 1.5rem 2rem;
  border: 3px solid $clr--panel-border;
  margin-bottom: 1rem;

  border-radius: 1.25rem;
  cursor: pointer;
  transition: background .2s ease-in;

  h3 {
    margin-bottom: 0;

    font-size: 1rem;
    transition: margin .1s ease .2s;
  }

  p {
    overflow: hidden;
    max-height: 0;
    margin-bottom: 0;

    opacity: 0;
    transition: all .2s ease-in;
  }

  &--active {
    background-color: #FFFFFF;

    &:after {
      transform: rotate(180deg);
    }

    h3 {
      margin-bottom: 1rem;

      transition-delay: 0s;
      transition-duration: 0s;
    }

    p {
      max-height: 100rem;

      opacity: 1;
    }
  }
}

section.ad {
  a {
    text-decoration: none;
    color: dimgrey;
    &:hover {
      text-decoration: none;
      color: black;
    }
  }
}

@media (max-width: 768px) {
  .section--home {
    margin-bottom: 0;
  }
  .media__body {
    height: auto;
    margin: 0;
  }
  .section--filled:before, .section--filled:after {
    height: 1rem;
  }
  .section--light:before, .section--light:after {
    height: 1rem;
  }
  .box__item--active {
    border-bottom-right-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
  }
  .box__item--active:after {
    display: none;
  }
}

