// Typography
// ==========

// Unordered list
// --------------

ul:not(.nav):not(.box):not(.breadcrumb):not(.admin-menu) {
	padding-left: .75rem;
	margin-bottom: 2rem;

	font-size: 1.125rem;
	list-style: none;

	@include media-breakpoint-up('sm') {
		padding-left: 1.5rem;
	}

	li {
		display: flex;

		padding: .25rem 0;

		line-height: 1.5rem;

		@include media-breakpoint-up('sm') {
			line-height: 2rem;
		}

		&:before {
			display: inline-block;
			height: 1.25rem;
			flex: 0 0 1.25rem;
			margin: .15rem 1rem .35rem 0;

			background: url(/media/img/check.svg) no-repeat 50% 50%;
			background-size: contain;
			content: '';

			@include media-breakpoint-up('sm') {
				height: 1.4375rem;
				flex-basis: 1.8125rem;
				margin: .35rem 1.5rem .35rem 0;
			}
		}
	}
}

// Paragraph
// ---------

p {
	line-height: 1.625rem;
}
