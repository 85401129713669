// Section
// =======

// Main component
// --------------

.section {
	margin: 4rem 0;

	&--home,
	&--main {
		@include media-breakpoint-up('xl') {
			&:after {
				background: url(/media/img/bcg-left.svg) no-repeat -500px -620px;
				background-size: 1000px;
			}
		}

		&:before,
		&:after {
			position: absolute;
			z-index: -1;
			top: 0;

			display: block;
			width: 100%;
			max-width: 920px;
			height: 1020px;

			content: '';
		}

		&:before {
			right: 0;
			background: url(/media/img/bcg-right.svg) no-repeat calc(100% + 100px) 0;
			background-size: cover;
		}
	}

	&--home {
		margin-bottom: 12rem;
	}

	&--main {
		margin: 4rem 0 5rem;

		&:before {
			background-position-y: calc(100% - 356px);
		}
	}

	&--light {
		background-color: $clr--primary-light;

		&:before,
		&:after {
			display: block;
			width: 100%;
			height: 7rem;

			background-size: cover;
			content: '';
		}

		&:before {
			background: url(/media/img/bcg-path-light-top.svg) no-repeat 50% -2%;
		}

		&:after {
			background: url(/media/img/bcg-path-light-bottom.svg) no-repeat 50% 102%;
		}
	}

	&--filled {
		background-color: $clr--primary-dark;

		&:before,
		&:after {
			display: block;
			width: 100%;
			height: 7rem;

			background-size: cover;
			content: '';
		}

		&:before {
			background: url(/media/img/bcg-path-top.svg) no-repeat 50% -2%;
		}

		&:after {
			background: url(/media/img/bcg-path-bottom.svg) no-repeat 50% 102%;
		}
	}
}
