// Breadcrumb
// ----------

// Main component
// --------------

.breadcrumb {

	display: inline-flex;
	align-items: flex-end;

	padding: 0;

	list-style: none;
}

// Item
// ----

.breadcrumb__item {
	position: relative;

	&:not(:last-of-type):after {
		position: absolute;
		top: 50%;
		right: -.1875rem;

		display: block;
		height: 1.625rem;

		color: #C7C3C2;
		content: '»';
		font-size: .875rem;
		transform: translateY(-50%);
	}

	@media (max-width: 25rem) {
		&:not(:first-of-type):not(:last-of-type):not(&--spec) {
			display: none;
		}
	}

	@media (min-width: 25rem) {
		&--spec {
			display: none;
		}
	}
}

// Link
// ----

.breadcrumb__link {
	display: inline-flex;
	align-items: center;
	padding: 1rem 1.5rem;

	border-bottom: 4px solid rgba(36, 38, 29, .0815);
	color: $clr--roman;
	line-height: 1rem;

	&:hover {
		color: $clr--roman;
	}

	.breadcrumb__item:first-of-type & {
		padding-left: 0;
	}

	.breadcrumb__item:last-of-type & {
		padding-right: 0;
	}

	img {
		width: 16px;
		height: 16px;
	}
}
