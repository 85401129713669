// Vertical Align
// ==============

// Middle
// ------

.ver-ali-middle {
	display: flex;
	height: 100%;

	align-items: center;
}
